import * as React from "react";
import LightenBackground from "src/components/common/LightenBackground";
import Separator from "src/components/common/Separator";
import About from "src/components/homePage/About";
import Contact from "src/components/homePage/Contact";
import Imprint from "src/components/homePage/Imprint";
import Privacy from "src/components/homePage/Privacy";
import Master from "src/components/Master";

const description =
    "Als Produktionsagentur verfügen wir über beste Kontakte zu den feinsten Adressen im Produktionsgewerbe für Werbemittel aller Art.";

const metaKeywords = [
    "Aufkleber",
    "Briefpapier",
    "Banner",
    "Bücher",
    "Blöcke",
    "Buchdruck",
    "Broschüren",
    "Druck",
    "Drucksachen",
    "Digitaldruck",
    "Flyer",
    "Kataloge",
    "Kuverts",
    "Magazine",
    "Ordner",
    "Zeitungen",
    "Schilder",
    "Visitenkarten",
    "Plakate",
    "Prospekte",
    "Grafik",
    "Werbegrafik",
    "Druckproduktion",
    "Werbedrucksorten",
    "Bürodrucksorten",
    "Hallwang",
    "Salzburg",
    "Österreich",
];

export default (): React.ReactNode => (
    <Master metaDescription={description} metaKeywords={metaKeywords.join(", ")} openGraphDescription={description}>
        <About />
        <Separator />
        <LightenBackground>
            <Contact />
            <Separator />
            <Imprint />
            <Separator />
            <Privacy />
        </LightenBackground>
    </Master>
);
