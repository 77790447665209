import * as React from "react";

import ContentWrapper from "../common/ContentWrapper";
import Text, { variants } from "../common/Text";
import * as sc from "./About.sc";

const About: React.FC = () => (
    <sc.Root>
        <ContentWrapper>
            <sc.ContentWrapper>
                <sc.HeadlineWrapper>
                    <Text variant={variants.headline1}>Die Produktionsagentur für Ihre Drucksachen</Text>
                </sc.HeadlineWrapper>
                <sc.TextWrapper>
                    <Text>
                        <strong>Das tun wir für Sie:</strong>
                        <br />
                        Als Produktionsagentur verfügen wir über beste Kontakte zu den feinsten Adressen im Produktionsgewerbe für Werbemittel aller
                        Art. So können wir den besten Partner für Ihren Auftrag auswählen und Ihnen das beste Preis-Leistungsverhältnis anbieten. Wir
                        überwachen die Produktion und kümmern uns um die Einhaltung der Lieferzeiten.
                    </Text>
                    <Text>
                        <strong>Machen Sie sich unsere langjährige Erfahrung im Print-Bereich zu Nutzen.</strong>
                    </Text>
                    <Text disableMargin>
                        Der <strong>UMWELTGEDANKE</strong> wird bei uns großgeschrieben und es zeigt sich, dass Qualität und Effizienz gut mit
                        Nachhaltigkeit und Umweltbewusstsein vereinbar sind. Wir produzieren ausschließlich in Österreich und dem benachbarten Bayern.
                    </Text>
                </sc.TextWrapper>
            </sc.ContentWrapper>
        </ContentWrapper>
        <sc.Aquarell />
    </sc.Root>
);

export default About;
