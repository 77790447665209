import * as React from "react";

import { constants } from "../../helpers";
import ContentWrapper from "../common/ContentWrapper";
import Link from "../common/Link";
import Text, { variants } from "../common/Text";
import * as sc from "./Imprint.sc";

const infoList: React.ReactNode[] = [
    constants.fullCompanyName,
    "Petersbergstraße 42",
    "5300 Hallwang, Austria",
    <Link href={`mailto:${constants.officeEmail}`} title={constants.officeEmail}>
        {constants.officeEmail}
    </Link>,
    "UID: ATU60766308",
    "FN: 180430p",
];

const Imprint: React.FC = () => (
    <ContentWrapper>
        <sc.ContentWrapper>
            <Text variant={variants.headline2}>Impressum</Text>
            <Text>
                {infoList.map((infoItem, index) => (
                    <sc.InfoItem key={index}>{infoItem}</sc.InfoItem>
                ))}
            </Text>
            <Text disableMargin>
                Website made by{" "}
                <Link href={constants.rickyWebsite} title="Ricky James Smith - Online Portfolio" target={"_blank"}>
                    Ricky James Smith
                </Link>
            </Text>
        </sc.ContentWrapper>
    </ContentWrapper>
);

export default Imprint;
