import styled from "styled-components";

import { breakpoints, colors } from "../../helpers";

export const ContentWrapper = styled.section`
    padding-top: 30px;
    padding-bottom: 30px;
`;

export const InfoItem = styled.span`
    display: inline-block;

    :after {
        content: "|";
        display: inline-block;
        padding-left: 5px;
        padding-right: 5px;
    }

    :last-child:after {
        display: none;
    }
`;
