import styled from "styled-components";

import { breakpoints } from "../../helpers";
import CommonLink from "../common/Link";

export const ContentWrapper = styled.section`
    padding-top: 30px;
    padding-bottom: 30px;
`;

export const Team = styled.div`
    @media (min-width: ${breakpoints.medium}px) {
        display: flex;
        align-items: flex-start;
    }
`;

export const Member = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;

    @media (min-width: ${breakpoints.medium}px) {
        width: 50%;
        box-sizing: border-box;

        :nth-child(odd) {
            padding-right: 10px;
        }

        :nth-child(even) {
            padding-left: 10px;
        }
    }

    :last-child {
        margin-bottom: 0;
    }
`;

export const Image = styled.img`
    width: 100px;
    margin-right: 15px;
    flex-shrink: 0;

    @media (min-width: ${breakpoints.small}px) {
        width: 120px;
    }

    @media (min-width: 450px) {
        width: 140px;
    }
`;

export const Info = styled.div`
    flex-shrink: 1;
    overflow: hidden;

    @media (min-width: ${breakpoints.large}px) {
        overflow: visible;
    }
`;

export const Link = styled(CommonLink)`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
