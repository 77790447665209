import * as React from "react";
import ContentWrapper from "src/components/common/ContentWrapper";
import GatsbyLink from "src/components/common/GatsbyLink";
import Text from "src/components/common/Text";
import { pageUrls } from "src/helpers";

import * as sc from "./Privacy.sc";

const Privacy: React.FC = () => (
    <ContentWrapper>
        <sc.ContentWrapper>
            <Text disableMargin>
                <GatsbyLink to={pageUrls.privacy}>Informationen zum Datenschutz</GatsbyLink>
            </Text>
        </sc.ContentWrapper>
    </ContentWrapper>
);

export default Privacy;
