import * as React from "react";
import ContentWrapper from "src/components/common/ContentWrapper";
import Text, { variants } from "src/components/common/Text";
import photoHarald from "src/images/harald.jpg";
import photoHarald2x from "src/images/harald@2x.jpg";

import * as sc from "./Contact.sc";

interface TeamMember {
    name: string;
    role: string;
    email: string;
    phone: string;
    image: string;
    image2x: string;
}

const teamMembers: TeamMember[] = [
    {
        name: "Harald Magenschab",
        role: "Geschäftsführer",
        email: "h.magenschab@druck-ohne-troubles.at",
        phone: "+43 (0)664 3360350",
        image: photoHarald,
        image2x: photoHarald2x,
    },
];

const Contact: React.FC = () => (
    <ContentWrapper>
        <sc.ContentWrapper>
            <Text variant={variants.headline2}>Kontakt</Text>
            <sc.Team>
                {teamMembers.map(({ name, role, email, phone, image, image2x }, index) => (
                    <sc.Member key={index}>
                        <sc.Image alt={name} title={name} src={image} srcSet={`${image} 1x, ${image2x} 2x`} />
                        <sc.Info>
                            <Text disableMargin>
                                <strong>{name}</strong>
                            </Text>
                            <Text disableMargin>{role}</Text>
                            <Text disableMargin>
                                <sc.Link href={`mailto:${email}`} title={email}>
                                    {email}
                                </sc.Link>
                            </Text>
                            <Text disableMargin>
                                <sc.Link href={`tel:${phone}`} title={phone}>
                                    {phone}
                                </sc.Link>
                            </Text>
                        </sc.Info>
                    </sc.Member>
                ))}
            </sc.Team>
        </sc.ContentWrapper>
    </ContentWrapper>
);

export default Contact;
