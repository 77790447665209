import styled from "styled-components";

import { breakpoints } from "../../helpers";
import aquarell from "../../images/aquarell.png";
import aquarell2x from "../../images/aquarell2x.png";

export const Root = styled.div`
    position: relative;
`;

export const ContentWrapper = styled.section`
    padding-top: 50px;

    @media (min-width: ${breakpoints.medium}px) {
        padding-bottom: 50px;
    }
`;

export const HeadlineWrapper = styled.div`
    @media (min-width: ${breakpoints.medium}px) {
        max-width: 420px;
    }
`;

export const TextWrapper = styled.div`
    @media (min-width: ${breakpoints.medium}px) {
        max-width: 345px;
    }
`;

export const Aquarell = styled.div`
    position: relative;
    width: 80%;
    margin-left: auto;
    max-width: 380px;

    @media (min-width: ${breakpoints.medium}px) {
        position: absolute;
        right: 0;
        bottom: 0;
        width: calc(100% - 345px);
        max-width: 600px;
    }

    :after {
        content: "";
        display: block;
        opacity: 0.85;
        height: 0;
        padding-bottom: ${(434 / 650) * 100}%;
        background-image: url(${aquarell});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;

        @media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
            background-image: url(${aquarell2x});
        }
    }
`;
